import { render, staticRenderFns } from "./activeNoOrder.vue?vue&type=template&id=c6b3e4de&scoped=true&"
import script from "./activeNoOrder.vue?vue&type=script&lang=js&"
export * from "./activeNoOrder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.2@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6b3e4de",
  null
  
)

export default component.exports